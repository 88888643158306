import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  createRef,
  useCallback,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
  Grid,
  Fab,
  Slide,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
  Fade,
} from "@material-ui/core";
import MapboxGl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  simulateLayer,
  touristPlacesSource,
  touristPlaces,
  touristPlacesClusterLayer,
  touristPlacesClusterCountLayer,
} from "../../constant/map-layers";
import Tableau from "../../container/tableau";
import clsx from "clsx";
import { TuneRounded } from "@material-ui/icons";
import TunnerPaper from "../../component/tourism-investment-tunnerpaper";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/user-info";

const headerHeight = 68;

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: "#f2f2f2",
    position: "fixed",
    height: "100vh",
    width: "100vw",
    zIndex: -1,
  },
  root: {
    maxHeight: `calc(100vh - (${headerHeight}px + 20px))`,
    overflowY: "auto",
    paddingTop: headerHeight,
  },
  gridContainer: { padding: `${theme.spacing(1)}px ${theme.spacing(3)}px` },
  content: { height: `calc(100vh - 130px)` },
  tableauContent: { "& div": { height: "100%" } },
  toggleTune: {
    position: "absolute",
    zIndex: 1,
    left: theme.spacing(1),
    top: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: theme.spacing(40),
    height: "100%",
    backgroundColor: `${theme.palette.common.white}fffa1`,
  },
  paperTourist: {
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(3),
    backgroundColor: `${theme.palette.common.white}fffa1`,
  },
  toggleTouristLayer: {
    position: "absolute",
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    zIndex: theme.spacing(1),
  },
}));

interface IParam {
  p: string;
  hydro: number;
  road: number;
  pop: number;
  salary: number;
  econ: number;
  tourist: number;
  simulate: boolean;
}

const initParam = {
  p: "00",
  hydro: 0.7,
  road: 0.7,
  pop: 0.7,
  salary: 0.7,
  econ: 0.7,
  tourist: 0.7,
  simulate: false,
};

const TourismInvestment = () => {
  const classes = useStyles();
  const refMap = useRef<HTMLDivElement>(null);
  const refBI: any = createRef();
  const [tunner, settunner] = useState<Boolean>(false);
  const [map, setmap] = useState<MapboxGl.Map | undefined>(undefined);
  const [param, setparam] = useState<IParam>(initParam);
  const [tableauParam, settableauParam] = useState<IParam>(initParam);
  const [toggleLayer, settoggleLayer] = useState<boolean>(true);
  const [dashboards, setdashboards] = useState<any>([]);

  const location = useLocation();
  const userContext = useContext(UserContext);
  const { userInfo } = userContext;

  const initDashboards = useCallback(() => {
    const path = location.pathname.split("/")[2];
    const category = userInfo?.categories.find(
      (cg: any) => cg.pathLink === path
    );
    const dashboards = category
      ? userInfo?.dasboard
          .filter((ds: any) => ds.category_id === category.id)
          .sort((a: any, b: any) => a.order_no - b.order_no)
      : [];
    setdashboards(
      dashboards.filter(
        (ds: any) => ds.view !== "SuitMap" && ds.view !== "SuitMap_Province"
      )
    );
  }, [location, userInfo]);

  useEffect(() => {
    initDashboards();
    return () => {};
  }, [initDashboards]);

  const toggleTouristLayer = () => {
    if (map) {
      if (toggleLayer) {
        map.setLayoutProperty("tourist-places", "visibility", "none");
        map.setLayoutProperty("tourist-places-cluster", "visibility", "none");
        map.setLayoutProperty(
          "tourist-places-cluster-count",
          "visibility",
          "none"
        );
      } else {
        map.setLayoutProperty("tourist-places", "visibility", "visible");
        map.setLayoutProperty(
          "tourist-places-cluster",
          "visibility",
          "visible"
        );
        map.setLayoutProperty(
          "tourist-places-cluster-count",
          "visibility",
          "visible"
        );
      }
      settoggleLayer(!toggleLayer);
    }
  };

  useEffect(() => {
    if (refMap && refMap.current) {
      const mapRender = new MapboxGl.Map({
        container: refMap.current,
        style: require("../../constant/map-styles.json"), // stylesheet location
        center: [102.81948, 16.4558],
        zoom: 5,
      });
      mapRender.on("load", (e: { type: string; target: MapboxGl.Map }) => {
        e.target.fitBounds([
          [97.3433584710000446, 5.613037873000053],
          [105.6369645180000418, 20.4650738150000535],
        ]);
        e.target.addSource("simulate", {
          type: "vector",
          tiles: [
            "https://tictile.mots.go.th/final-suit-tourism-investment/{z}/{x}/{y}",
          ],
          minzoom: 0,
          maxzoom: 14,
        });
        e.target.addSource("tourist-places-source", touristPlacesSource);
        e.target.addLayer(simulateLayer, "water");
        e.target.addLayer(touristPlaces);
        e.target.addLayer(touristPlacesClusterLayer);
        e.target.addLayer(touristPlacesClusterCountLayer);

        e.target.on("click", "simulate", (e: any) => {
          const features = mapRender.queryRenderedFeatures(e.point);
          console.log(features);
        });

        setmap(e.target);
      });
    }

    return () => {
      setmap(undefined);
    };
  }, [refMap]);

  const genSuitExpessionPaint = (suit: string) => {
    const rr = 0.0333333333333333;
    const popCal = ["*", tableauParam.pop, ["get", "pop"], rr];
    const hydroCal = ["*", tableauParam.hydro, ["get", "hydro"], rr];
    const roadCal = ["*", tableauParam.road, ["get", "road"], rr];
    const salaryCal = ["*", tableauParam.salary, ["get", "salary"], rr];
    const econCal = ["*", tableauParam.econ, ["get", "econ"], rr];
    const touristCal = ["*", tableauParam.tourist, ["get", "tourist"], rr];
    const finalSum = [
      "+",
      popCal,
      hydroCal,
      roadCal,
      salaryCal,
      econCal,
      touristCal,
    ];
    const allArrayStyle = [
      {
        key: "เหมาะสมน้อย",
        paintFill: [
          ["<=", tableauParam.simulate ? finalSum : ["get", "default"], 0.25],
          "#B3E0A6",
        ],
      },
      {
        key: "เหมาะสมปานกลาง",
        paintFill: [
          [
            "all",
            [">", tableauParam.simulate ? finalSum : ["get", "default"], 0.25],
            ["<=", tableauParam.simulate ? finalSum : ["get", "default"], 0.5],
          ],
          "#73BA67",
        ],
      },
      {
        key: "เหมาะสมมาก",
        paintFill: [
          [
            "all",
            [">", tableauParam.simulate ? finalSum : ["get", "default"], 0.5],
            ["<=", tableauParam.simulate ? finalSum : ["get", "default"], 0.75],
          ],
          "#44924E",
        ],
      },
      {
        key: "เหมาะสมมากที่สุด",
        paintFill: [
          [">", tableauParam.simulate ? finalSum : ["get", "default"], 0.75],
          "#24693D",
        ],
      },
    ];
    const indexSuit = allArrayStyle.findIndex((obj: any) => obj.key === suit);
    let expenssionArray = new Array(0);
    if (suit === "all") {
      allArrayStyle.map((obj: any) => {
        expenssionArray = [...expenssionArray, ...obj.paintFill];
        return false;
      });
    } else {
      allArrayStyle
        .filter((obj: any, i: number) => i === indexSuit)
        .map((obj: any) => {
          expenssionArray = [...expenssionArray, ...obj.paintFill];
          return false;
        });
    }

    return expenssionArray;
  };

  const eventFromBI = (event: any) => {
    const worksheet = event._worksheetImpl$1._name;
    if (worksheet === "Suit Icon") {
      event.getMarksAsync().then((data: any) => {
        const paires = data.filter((rs: any) => rs.getPairs());
        if (paires.length) {
          const paireProps = paires[0].getPairs();
          const paintFillExpasseion = genSuitExpessionPaint(
            paireProps[0].value
          );
          map?.setPaintProperty("simulate", "fill-color", [
            "case",
            ...paintFillExpasseion,
            "rgba(255,255,255,0)",
          ]);
        } else {
          const paintFillExpasseion = genSuitExpessionPaint("all");
          map?.setPaintProperty("simulate", "fill-color", [
            "case",
            ...paintFillExpasseion,
            "rgba(255,255,255,0)",
          ]);
        }
      });
    }
  };

  return (
    <Fragment>
      <div className={classes.bg} />
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Typography variant="body1">
              การจำลองสถานการณ์แบบจำลองพื้นที่เหมาะสมการลงทุนด้านการท่องเที่ยว
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.content}>
            <div ref={refMap} style={{ height: "100%", position: "relative" }}>
              <Fade in={Boolean(!tunner)}>
                <div className={classes.toggleTouristLayer}>
                  <Paper className={classes.paperTourist}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={toggleLayer}
                          onChange={toggleTouristLayer}
                          name="checkedA"
                        />
                      }
                      label="สถานที่ท่องเที่ยว"
                    />
                  </Paper>
                </div>
              </Fade>

              {!tunner && (
                <Fab
                  variant="round"
                  color="secondary"
                  size="small"
                  className={classes.toggleTune}
                  onClick={() => {
                    settunner(!tunner);
                  }}
                >
                  <TuneRounded fontSize="small" />
                </Fab>
              )}
              <Slide
                in={Boolean(tunner)}
                direction="right"
                mountOnEnter
                unmountOnExit
              >
                <Paper className={classes.paper}>
                  <TunnerPaper
                    settunner={settunner}
                    param={param}
                    setparam={setparam}
                    map={map}
                    settableauParam={settableauParam}
                  />
                </Paper>
              </Slide>
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            className={clsx(classes.tableauContent, classes.content)}
          >
            <Tableau
              ref={refBI}
              url={
                tableauParam.p !== "00"
                  ? "https://ticbi.mots.go.th/views/SuitablityMap/SuitMap_Province"
                  : "https://ticbi.mots.go.th/views/SuitablityMap/SuitMap"
              }
              parameters={
                tableauParam.simulate && tableauParam.p !== "00"
                  ? {
                      P_Parameter: tableauParam.p,
                      Econ_Weight: tableauParam.econ,
                      Hydro_Weight: tableauParam.hydro,
                      Pop_Weight: tableauParam.pop,
                      Road_Weight: tableauParam.road,
                      Salary_Weight: tableauParam.salary,
                      Tourist_Weight: tableauParam.tourist,
                    }
                  : !tableauParam.simulate && tableauParam.p !== "00"
                  ? {
                      P_Parameter: tableauParam.p,
                    }
                  : tableauParam.simulate && tableauParam.p === "00"
                  ? {
                      Econ_Weight: tableauParam.econ,
                      Hydro_Weight: tableauParam.hydro,
                      Pop_Weight: tableauParam.pop,
                      Road_Weight: tableauParam.road,
                      Salary_Weight: tableauParam.salary,
                      Tourist_Weight: tableauParam.tourist,
                    }
                  : undefined
              }
              markselect={eventFromBI}
            />
          </Grid>
          {dashboards.map((ds: any) => (
            <Grid key={ds.view} item xs={12} className={classes.tableauContent}>
              <Tableau url={ds.url} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Fragment>
  );
};

export default TourismInvestment;
