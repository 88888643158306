import React, { createContext, useState } from "react";

interface Iuser {
  dasboard: any;
  categories: {
    id: number;
    name: string;
    pathLink: string;
    component: React.FunctionComponent;
    icon: any;
    countDashboard: number;
  }[];
  role_id?: number;
  organization_id?: number;
  applications?: number[];
}

interface UserContextInterface {
  userInfo: Iuser | undefined;
  setUserInfo: Function;
}

const initalState: UserContextInterface = {
  userInfo: undefined,
  setUserInfo: () => {},
};

export const UserContext = createContext<UserContextInterface>(initalState);
export const UserContextProvider = (props: any) => {
  const [userInfo, setUserInfo] = useState<Iuser | undefined>(undefined);
  return (
    <UserContext.Provider
      value={{ userInfo: userInfo, setUserInfo: setUserInfo }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export const UserContextConsumer = UserContext.Consumer;
