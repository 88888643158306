import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
// import WillComeback from "../../material/images/will-comeback.png";
import Tableau from "../../container/tableau";

const headerHeight = 64;
const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    position: "fixed",
    zIndex: -1,
    height: "100vh",
    width: "100vw",
    background: "#f2f2f2",
  },
  root: {
    maxHeight: `calc(100vh - (${headerHeight}px + 20px))`,
    overflowY: "auto",
    paddingTop: headerHeight,
  },
  gridContainer: { padding: `${theme.spacing(1)}px ${theme.spacing(3)}px` },
  content: {
    height: `calc(100vh - 104px)`,

    "& div": { height: "100%", width: "100%" },
    display: "flex",
    justifyContent: "center",
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.bg}></div>
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} className={classes.content}>
            {/* <div style={{ textAlign: "center" }}>
              <img src={WillComeback} alt="will-comeback" width="75%" />
            </div> */}

            <Tableau url="https://ticbi.mots.go.th/views/LandingInfographic/Landing_1" />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default Landing;
