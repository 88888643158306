import React, { useContext } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Layout from "../../container/layout";
import Landing from "./landing";
import { UserContext } from "../../contexts/user-info";

export default () => {
  const match = useRouteMatch();
  const userContext = useContext(UserContext);
  const { userInfo } = userContext;
  return userInfo ? (
    <Layout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/landing`} />
        <Route path={`${match.url}/landing`} component={Landing} />
        {userInfo.categories.map(
          (category: {
            id: number;
            name: string;
            pathLink: string;
            component: React.FunctionComponent;
          }) => (
            <Route
              key={category.pathLink}
              path={`${match.url}/${category.pathLink}`}
              component={category.component}
            />
          )
        )}
        <Redirect to="/not-found" />
      </Switch>
    </Layout>
  ) : null;
};
