import React, { Fragment, useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Grid,
  // InputAdornment,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
  IconButton,
  Fab,
  Popover,
  Tooltip,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  // Search as SearchIcon,
  Apps as AppsIcon,
  Message as MessageIcon,
  ChevronLeft,
  ChevronRight,
  // BarChart,
  ExitToApp,
  LockOpen,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Bandner from "../component/bandner";
import { UserContext } from "../contexts/user-info";
import { Balloon } from "../material/icons";

const buildjsonLink = require("../build.json");

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  appbar: { backgroundColor: "unset", boxShadow: "unset" },
  appbarFill: { backgroundColor: "#f2f2f2", boxShadow: "unset" },
  notiIcon: { display: "flex", justifyContent: "flex-end" },
  flexMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: { margin: `0px ${theme.spacing(1)}px` },
  leftPage: {
    position: "fixed",
    top: "calc(50vh - 15px)",
    left: theme.spacing(1) / 2,
    zIndex: theme.spacing(2),
  },
  rightPage: {
    position: "fixed",
    top: "calc(50vh - 15px)",
    right: theme.spacing(1) / 2,
  },
  directIcon: {
    width: 30,
    height: 30,
    minHeight: "unset",
    backgroundColor: "#2b2b2b",
    "&:hover": { backgroundColor: "#000000" },
  },
  directIconColor: { color: "#ffffff" },
  search: {
    position: "relative",
    borderRadius: theme.spacing(8),
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: "100%",
    "& div.MuiInput-formControl": {
      paddingLeft: 16,
      paddingTop: 4,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: `100%`,
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  colorWhite: {
    color: "#ffffff",
  },
  appMenu: {
    backgroundImage: `linear-gradient(90deg, #3b82e1c2,#22b6d8c2 )`,
    backgroundColor: "unset",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    width: theme.spacing(67),
  },
  gridItem: { "& :hover": { cursor: "pointer" } },
  appItem: { display: "flex", justifyContent: "center" },
  build: { padding: `0px ${theme.spacing(1)}px` },
}));

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Layout = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const userContext = useContext(UserContext);
  const { userInfo, setUserInfo } = userContext;

  console.log(downSm);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePage = (page: String) => () => {
    const allCookies = document.cookie.split(";");
    allCookies.map(
      (cookie: string) =>
        (document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`)
    );
    history.push(`/app/${page}`);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    setUserInfo(undefined);
    history.push(`/auth/login`);
  };

  const handleChangePage = (direction: "L" | "R") => () => {
    const arrayPath = history.location.pathname.split("/");
    if (userInfo) {
      const allCookies = document.cookie.split(";");
      allCookies.map(
        (cookie: string) =>
          (document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`)
      );
      const currentIndex = userInfo.categories.findIndex(
        (category: any) => category.pathLink === arrayPath[arrayPath.length - 1]
      );
      if (direction === "L") {
        if (currentIndex === 0) {
          history.push(
            `/app/${
              userInfo.categories[userInfo.categories.length - 1].pathLink
            }`
          );
        } else {
          history.push(
            `/app/${userInfo.categories[currentIndex - 1].pathLink}`
          );
        }
      } else {
        if (currentIndex === userInfo.categories.length - 1) {
          history.push(`/app/${userInfo.categories[0].pathLink}`);
        } else {
          history.push(
            `/app/${userInfo.categories[currentIndex + 1].pathLink}`
          );
        }
      }
    }
  };

  const _controlJivoChat = () => {
    const existingScript = document.getElementById("jivo-script");
    const jvlabelWrap = document.getElementById("jvlabelWrap");
    if (existingScript && jvlabelWrap) {
      if (jvlabelWrap.style.display === "") {
        jvlabelWrap.style.display = "none";
      } else {
        jvlabelWrap.style.display = "";
      }
    } else {
      const script = document.createElement("script");
      script.src = "//code.jivosite.com/widget/4cJZZqRGLS"; // URL for the third-party library being loaded.
      script.id = "jivo-script"; // e.g., googleMaps or stripe
      document.body.appendChild(script);
    }
  };

  const _changePageDashboard = (e: any, value: any) => {
    if (value) {
      const category = userInfo?.categories.find(
        (cg: any) => cg.id === value.category_id
      );
      if (category) {
        history.push(`/app/${category.pathLink}`);
      }
    }
  };

  return (
    <Fragment>
      <HideOnScroll {...props}>
        <AppBar color="default" className={classes.appbarFill}>
          <Toolbar>
            <Grid container style={{ justifyContent: "space-between" }}>
              <Grid item xs={6} sm={8} md={6} style={{ display: "flex" }}>
                <Bandner />
              </Grid>
              <Grid item sm={4} md={2} className={clsx(classes.flexMiddle)}>
                <div className={classes.notiIcon}>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    className={clsx(classes.icon)}
                  >
                    <AppsIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={clsx(classes.icon)}
                    onClick={_controlJivoChat}
                  >
                    <MessageIcon />
                  </IconButton>
                  <Tooltip title={userInfo?.role_id ? "Logout" : "Login"}>
                    <IconButton
                      size="small"
                      className={clsx(classes.icon)}
                      onClick={handleLogout}
                    >
                      {userInfo?.role_id ? <ExitToApp /> : <LockOpen />}
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={false}
                md={4}
                className={classes.flexMiddle}
              >
                {userInfo && !downSm && (
                  <Autocomplete
                    id="combo-box-demo"
                    options={userInfo.dasboard}
                    getOptionLabel={(option) => option.name}
                    onChange={_changePageDashboard}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"ค้นหาหัวข้อ"}
                        className={classes.inputRoot}
                        classes={{ root: classes.search }}
                        margin="dense"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Popover
            id={"popover-menu"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{ paper: classes.appMenu }}
          >
            <Grid container spacing={1}>
              {userInfo?.categories.map((category: any) => (
                <Grid
                  key={category.pathLink}
                  item
                  xs={3}
                  className={classes.gridItem}
                  onClick={handlePage(category.pathLink)}
                >
                  <div className={classes.appItem}>{category.icon}</div>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.colorWhite}
                    align="center"
                  >
                    {category.name}
                  </Typography>
                </Grid>
              ))}
              <Grid
                item
                xs={3}
                className={classes.gridItem}
                onClick={() => {
                  window.open(
                    "https://www.mots.go.th/more_news_new.php?cid=411",
                    "_blank"
                  );
                }}
              >
                <div className={classes.appItem}>
                  <Balloon
                    style={{
                      fontSize: 40,
                      color: "#dbdee0",
                      padding: 8,
                    }}
                  />
                </div>
                <Typography
                  variant="caption"
                  component="div"
                  className={classes.colorWhite}
                  align="center"
                >
                  ข้อมูลดิบสถิติการท่องเที่ยว
                </Typography>
              </Grid>
            </Grid>
          </Popover>
        </AppBar>
      </HideOnScroll>
      <div>{props.children}</div>
      {history.location.pathname !== "/app/landing" && (
        <Fragment>
          <Fab
            size="small"
            color="default"
            aria-label="left"
            className={classes.leftPage}
            classes={{ root: classes.directIcon }}
            onClick={handleChangePage("L")}
          >
            <ChevronLeft className={classes.directIconColor} />
          </Fab>
          <Fab
            size="small"
            color="default"
            aria-label="right"
            className={classes.rightPage}
            classes={{ root: classes.directIcon }}
            onClick={handleChangePage("R")}
          >
            <ChevronRight className={classes.directIconColor} />
          </Fab>
        </Fragment>
      )}
      <div className={classes.build}>
        <Typography variant="caption" color="textSecondary">
          {`version: ${buildjsonLink.build}, ${buildjsonLink.date}`}
        </Typography>
      </div>
    </Fragment>
  );
};

export default Layout;
