import React, { useState, useCallback, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import TableauReport from "tableau-react";
import { callToken } from "../api";

interface ITableau {
  url: string;
  parameters?: any;
  markselect?: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { position: "relative" },
  printBtn: {
    position: "absolute",
    right: 0,
    textTransform: "unset",
    borderRadius: theme.spacing(3),
    fontSize: "0.75rem",
    padding: `0px ${theme.spacing(1)}px`,
    opacity: 0.5,
    "&:hover": { opacity: 1 },
  },
}));

const Tableau = forwardRef((props: ITableau, ref?: any) => {
  const classes = useStyles();
  const [token, settoken] = useState<string | undefined>(undefined);
  const [stateParam, setstateParam] = useState<any>(undefined);
  const { url, parameters } = props;

  const init = useCallback(async () => {
    const rsToken = await callToken();
    if (rsToken.data) {
      settoken(rsToken.data[0].token);
      if (props.markselect) {
        try {
          ref.current?.viz?.removeEventListener(
            "marksSelection",
            props.markselect
          );
        } catch (error) {}
        ref.current?.viz?.addEventListener("marksSelection", props.markselect);
      }
    }
  }, [ref, props.markselect]);

  useEffect(() => {
    if (parameters) {
      if (JSON.stringify(stateParam) !== JSON.stringify(parameters)) {
        settoken(undefined);
        setstateParam(parameters);
        init();
      }
    } else if (!parameters && stateParam) {
      settoken(undefined);
      setstateParam(undefined);
      init();
    }

    return () => {};
  }, [parameters, stateParam, init]);

  useEffect(() => {
    init();
    return () => {};
  }, [init]);

  return (
    <div className={classes.root}>
      {token && (
        <TableauReport
          ref={ref}
          url={url}
          token={token}
          options={{
            width: "100%",
            height: "100%",
            hideTabs: true,
            hideToolbar: true,
          }}
          parameters={stateParam ? stateParam : undefined}
          query="?:origin=card_share_link&:embed=n&:render=false"
        />
      )}
    </div>
  );
});

export default Tableau;
