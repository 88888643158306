import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Tableau from "../../container/tableau";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/user-info";

const headerHeight = 64;

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: "#f2f2f2",
    position: "fixed",
    height: "100vh",
    width: "100vw",
    zIndex: -1,
  },
  root: {
    maxHeight: `calc(100vh - (${headerHeight}px + 20px))`,
    overflowY: "auto",
    paddingTop: headerHeight,
  },
  gridContainer: { padding: `${theme.spacing(1)}px ${theme.spacing(3)}px` },
  content: { height: `calc(100vh - 104px)`, "& div": { height: "100%" } },
}));

const Imf = () => {
  const classes = useStyles();
  const [dashboards, setdashboards] = useState<any>([]);

  const location = useLocation();
  const userContext = useContext(UserContext);
  const { userInfo } = userContext;

  const initDashboards = useCallback(() => {
    const path = location.pathname.split("/")[2];
    const category = userInfo?.categories.find(
      (cg: any) => cg.pathLink === path
    );
    const dashboards = category
      ? userInfo?.dasboard
          .filter((ds: any) => ds.category_id === category.id)
          .sort((a: any, b: any) => a.order_no - b.order_no)
      : [];
    setdashboards(dashboards);
  }, [location, userInfo]);

  useEffect(() => {
    initDashboards();
    return () => {};
  }, [initDashboards]);

  return (
    <Fragment>
      <div className={classes.bg} />
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.gridContainer}>
          {dashboards.map((ds: any) => (
            <Grid key={ds.view} item xs={12} className={classes.content}>
              <Tableau url={ds.url} />
            </Grid>
          ))}
          {/* <Grid item xs={12} className={classes.content}>
            <Tableau url="https://ticbi.mots.go.th/views/IMF/IMF_1" />
          </Grid> */}
        </Grid>
      </div>
    </Fragment>
  );
};

export default Imf;
