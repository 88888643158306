export const signIn = (user: string, pass: string) => {
  try {
    const reqBody = {
      username: user,
      password: pass
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(reqBody)
      }
    )
      .then(response => response.json())
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
