import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Slider,
  Input,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from "@material-ui/core";
import {
  ClearRounded,
  AttachMoneyRounded,
  GroupRounded,
  TerrainRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { provinces } from "../constant/administrators";
import MapboxGl from "mapbox-gl";
import { FaRoad, FaTint, FaStethoscope } from "react-icons/fa";

interface ITunnerPaper {
  param: any;
  setparam: Function;
  settunner: Function;
  settableauParam: Function;
  map: MapboxGl.Map | undefined;
}

const compareValues = (key: string, order: "asc" | "desc") => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  header: { display: "flex", justifyContent: "space-between" },
  formSelect: {
    "& .MuiOutlinedInput-root": { borderRadius: theme.spacing(4) },
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  inputTunner: {
    width: 48,
    fontSize: "small",
    "& input": { textAlign: "center" },
  },
  expansionPanel: { backgroundColor: "unset", boxShadow: "unset" },
  expansionDiv: {
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
  },
  content: {
    height: `calc(100vh - 237px)`,
    overflowY: "auto",
  },
}));

const tunnerList = [
  { key: "salary", label: "สภาพเศรษฐกิจ", icon: <AttachMoneyRounded /> },
  { key: "pop", label: "สภาพทั่วไปด้านสังคม", icon: <GroupRounded /> },
  {
    key: "road",
    label: "ความสามารถในการเข้าถึงพื้นที่",
    icon: <FaRoad style={{ height: 24, width: 24 }} />,
  },
  {
    key: "hydro",
    label: "ความสามารถการใช้น้ำ",
    icon: <FaTint style={{ height: 24, width: 24 }} />,
  },
  {
    key: "econ",
    label: "การบริการด้านสาธารณูปโภค",
    icon: <FaStethoscope style={{ height: 24, width: 24 }} />,
  },
  { key: "tourist", label: "แหล่งท่องเที่ยว", icon: <TerrainRounded /> },
];

const initParam = {
  p: "00",
  hydro: 1,
  road: 1,
  pop: 1,
  salary: 1,
  econ: 1,
  tourist: 1,
  simulate: false,
};

const TunnerPaper = (props: ITunnerPaper) => {
  const classes = useStyles();

  const handleSliderChange = (type: string) => (
    event: any,
    newValue: number | number[]
  ) => {
    props.setparam({ ...props.param, [type]: newValue });
  };

  const handleInputChange = (type: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setparam({ ...props.param, [type]: Number(event.target.value) });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="body1">ค้นหาข้อมูลตามจังหวัด</Typography>
        <IconButton
          size="small"
          onClick={() => {
            props.settunner(false);
          }}
        >
          <ClearRounded fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formSelect}
        >
          <Select
            classes={{ selectMenu: classes.select }}
            value={props.param.p}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              props.setparam({
                ...props.param,
                p: event.target.value as string,
                // simulate: false,
              });
              const selectProv = provinces.find(
                (p: any) => p.prov_code === Number(event.target.value)
              );
              if (selectProv && props.map) {
                props.map.fitBounds([
                  [selectProv.minx, selectProv.miny],
                  [selectProv.maxx, selectProv.maxy],
                ]);
              }
            }}
          >
            <MenuItem value="00">ทุกจังหวัด</MenuItem>
            {provinces
              .sort(compareValues("prov_name_t", "asc"))
              .map((p: any) => (
                <MenuItem key={p.prov_code} value={String(p.prov_code)}>
                  {p.prov_name_t}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.content}>
        <ExpansionPanel
          className={classes.expansionPanel}
          expanded={props.param.simulate}
          onChange={(event: object, expanded: boolean) => {
            props.setparam({
              ...props.param,
              // p: "00",
              simulate: Boolean(expanded),
            });
          }}
        >
          <ExpansionPanelSummary
            className={classes.expansionDiv}
            expandIcon={<ExpandMoreRounded />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>จำลองสถานการณ์</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDiv}>
            <Grid container spacing={1}>
              {tunnerList.map((tunner: any) => (
                <Grid key={tunner.key} item xs={12}>
                  <Typography
                    id={`${tunner.key}-input-slider`}
                    variant="body2"
                    gutterBottom
                  >
                    {tunner.label}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>{tunner.icon}</Grid>
                    <Grid item xs>
                      <Slider
                        min={0}
                        max={1}
                        step={0.01}
                        value={props.param[tunner.key]}
                        onChange={handleSliderChange(tunner.key)}
                        aria-labelledby={`${tunner.key}-input-slider`}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        value={props.param[tunner.key]}
                        margin="dense"
                        onChange={handleInputChange(tunner.key)}
                        inputProps={{
                          step: 0.01,
                          min: 0,
                          max: 1,
                          type: "number",
                          "aria-labelledby": `${tunner.key}-input-slider`,
                        }}
                        className={classes.inputTunner}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.setparam(initParam);
            props.settableauParam(initParam);
            props.map?.setPaintProperty("simulate", "fill-color", [
              "case",
              ["<=", ["get", "default"], 0.25],
              "#B3E0A6",
              [
                "all",
                [">", ["get", "default"], 0.25],
                ["<=", ["get", "default"], 0.5],
              ],
              "#73BA67",
              [
                "all",
                [">", ["get", "default"], 0.5],
                ["<=", ["get", "default"], 0.75],
              ],
              "#44924E",
              "#24693D",
            ]);
            props.map?.fitBounds([
              [97.3433584710000446, 5.613037873000053],
              [105.6369645180000418, 20.4650738150000535],
            ]);
          }}
          style={{ marginRight: 16 }}
        >
          ล้าง
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const rr = 0.0333333333333333;
            props.settableauParam(props.param);

            const popCal = ["*", props.param.pop, ["get", "pop"], rr];
            const hydroCal = ["*", props.param.hydro, ["get", "hydro"], rr];
            const roadCal = ["*", props.param.road, ["get", "road"], rr];
            const salaryCal = ["*", props.param.salary, ["get", "salary"], rr];
            const econCal = ["*", props.param.econ, ["get", "econ"], rr];
            const touristCal = [
              "*",
              props.param.tourist,
              ["get", "tourist"],
              rr,
            ];
            const finalSum = [
              "+",
              popCal,
              hydroCal,
              roadCal,
              salaryCal,
              econCal,
              touristCal,
            ];
            props.map?.setPaintProperty("simulate", "fill-color", [
              "case",
              ["<", props.param.simulate ? finalSum : ["get", "default"], 0.25],
              "#B3E0A6",
              [
                "all",
                [
                  ">=",
                  props.param.simulate ? finalSum : ["get", "default"],
                  0.25,
                ],
                ["<", finalSum, 0.5],
              ],
              "#73BA67",
              [
                "all",
                [
                  ">=",
                  props.param.simulate ? finalSum : ["get", "default"],
                  0.5,
                ],
                [
                  "<",
                  props.param.simulate ? finalSum : ["get", "default"],
                  0.75,
                ],
              ],
              "#44924E",
              [
                ">=",
                props.param.simulate ? finalSum : ["get", "default"],
                0.75,
              ],
              "#24693D",
              "rgba(255,255,255,0)",
            ]);
          }}
        >
          คำนวน
        </Button>
      </Grid>
    </Grid>
  );
};

export default TunnerPaper;
