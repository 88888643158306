import MapboxGL from "mapbox-gl";

export const simulateLayer: MapboxGL.Layer | any = {
  id: "simulate",
  type: "fill",
  source: "simulate",
  "source-layer": "finalsuittourisminvestment",
  paint: {
    "fill-color": [
      "case",
      ["<", ["get", "default"], 0.25],
      "#B3E0A6",
      ["all", [">=", ["get", "default"], 0.25], ["<", ["get", "default"], 0.5]],
      "#73BA67",
      ["all", [">=", ["get", "default"], 0.5], ["<", ["get", "default"], 0.75]],
      "#44924E",
      [">=", ["get", "default"], 0.75],
      "#24693D",
      "rgba(255,255,255,0)",
    ],
    "fill-opacity": 1,
    "fill-outline-color": "rgba(255, 255, 0, 0)",
  },
};

export const touristPlacesSource: MapboxGL.VectorSource | any = {
  type: "vector",
  tiles: [
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/tourist-places/map-tiles/{z}/{x}/{y}`,
    // `http://192.168.1.38:30055/5e68570b96bb6b8cc288741a/{z}/{x}/{y}`
  ],
  minzoom: 1,
  maxzoom: 24,
};

export const touristPlaces: MapboxGL.Layer | any = {
  id: "tourist-places",
  type: "circle",
  source: "tourist-places-source",
  "source-layer": "5e68570b96bb6b8cc288741a",
  paint: {
    "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 1, 15, 7.5],
    "circle-color": [
      "case",
      ["==", ["get", "category"], "Tourist"],
      "rgb(15, 46, 71)",
      ["==", ["get", "category"], "Hotel"],
      "rgb(65, 192, 193)",
      ["==", ["get", "category"], "Hostel"],
      "rgb(149, 168, 169)",
      ["==", ["get", "category"], "Restaurants"],
      "rgb(236, 89, 96)",
      ["==", ["get", "category"], "Spa"],
      "rgb(243, 111, 95)",
      "rgb(25, 25, 25)", // default
    ],
    "circle-stroke-color": "#ffffff",
    "circle-stroke-width": 1.5,
  },
  layout: {
    // "icon-allow-overlap": true
  },
  filter: ["!", ["has", "point_count"]],
};

export const touristPlacesClusterLayer: MapboxGL.Layer | any = {
  id: "tourist-places-cluster",
  type: "circle",
  source: "tourist-places-source",
  "source-layer": "5e68570b96bb6b8cc288741a",
  paint: {
    "circle-color": [
      "interpolate",
      ["linear"],
      ["get", "point_count"],
      2,
      "#7074c0",
      1000,
      "#3f438f",
    ],
    "circle-opacity": 0.75,
    "circle-radius": [
      "interpolate",
      ["linear"],
      ["get", "point_count"],
      2,
      10,
      100,
      25,
    ],
  },
  layout: {
    // "icon-allow-overlap": true
  },
  filter: ["has", "point_count"],
  // filter: ["!in", "id", ""] // mapContext.map.setFilter("points-layer", ["in", "id", props.data]);
};

export const touristPlacesClusterCountLayer: MapboxGL.Layer | any = {
  id: "tourist-places-cluster-count",
  type: "symbol",
  source: "tourist-places-source",
  "source-layer": "5e68570b96bb6b8cc288741a",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count}",
    "text-font": ["Metropolis Regular", "Kanit"],
    "text-size": 12,
    "text-allow-overlap": true,
  },
  paint: {
    "text-color": "#ffffff",
  },
};
