import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Collapse,
} from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ReactResizeDetector from "react-resize-detector";
import { getResearches } from "../../api";
import { Close, CloudDownload } from "@material-ui/icons";

const headerHeight = 68;

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: "#f2f2f2",
    position: "fixed",
    height: "100vh",
    width: "100vw",
    zIndex: -1,
  },
  root: {
    maxHeight: `calc(100vh - (${headerHeight}px + 20px))`,
    overflowY: "auto",
    paddingTop: headerHeight,
  },
  gridContainer: { padding: `${theme.spacing(1)}px ${theme.spacing(3)}px` },
  title: { fontFamily: "Prompt-ExtraLight" },
  content: {
    height: `calc(100vh - 136px)`,
    position: "relative",
    "& div": { height: "100%" },
  },
  paper: {
    position: "absolute",
    width: `40%`,
    marginTop: theme.spacing(9),
    zIndex: 8,
    borderRadius: theme.spacing(3),
    right: theme.spacing(1),
    backgroundColor: "#ffffffe8",
  },
  clearHeight: { "& div": { height: "unset" } },
  closeGrid: {
    position: "absolute",
    top: -theme.spacing(2),
    right: -theme.spacing(2),
  },
  closeBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  tbContainer: { height: `calc(100% - 100px) !important` },
}));

const TourismResearch = () => {
  const classes = useStyles();
  const rsChart = useRef<HTMLDivElement | null>(null);
  const paperRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [rawdata, setrawdata] = useState<any>([]);
  const [queryResearch, setqueryResearch] = useState<any>([]);
  const [show, setshow] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [keyword, setkeyword] = useState<string>("");
  const [tableheight, settableheight] = useState<number>(200);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const init = useCallback(async () => {
    const rsResearchs = await getResearches();
    if (rsResearchs.data) {
      const rawData = rsResearchs.data;
      setrawdata(rawData);
      const hitEvent = (event: any) => {
        const dataItem = event.target.children.values[0];
        const { owner_ministry_name, file_type_name, main_category_name } =
          JSON.parse(dataItem.dataItem._dataContext.data);
        if (dataItem.dataItem.level === 2) {
          const rsQuery = rawData.filter(
            (rs: any) =>
              rs.owner_ministry_name === owner_ministry_name &&
              rs.file_type_name === file_type_name &&
              rs.main_category_name === main_category_name
          );
          setqueryResearch(rsQuery);
        } else if (dataItem.dataItem.level === 1) {
          const rsQuery = rawData.filter(
            (rs: any) =>
              rs.owner_ministry_name === owner_ministry_name &&
              rs.file_type_name === file_type_name
          );
          setqueryResearch(rsQuery);
        } else if (dataItem.dataItem.level === 0) {
          const rsQuery = rawData.filter(
            (rs: any) => rs.owner_ministry_name === owner_ministry_name
          );
          setqueryResearch(rsQuery);
        }
      };
      const dupMinistry = rawData.map((item: any) => item.owner_ministry_name);
      const ministry = dupMinistry.filter(
        (item: any, index: number) => dupMinistry.indexOf(item) === index
      );

      const research = ministry.map((mintry: string, index: number) => ({
        name: mintry,
        data: JSON.stringify({
          owner_ministry_name: mintry,
        }),
        children: rawData
          .filter((data: any) => data.owner_ministry_name === mintry)
          .map((d: any) => d.file_type_name)
          .filter(
            (item: any, i: number) =>
              rawData
                .filter((data: any) => data.owner_ministry_name === mintry)
                .map((d: any) => d.file_type_name)
                .indexOf(item) === i
          )
          .map((tf: any) => ({
            name: tf,
            value: 1,
            data: JSON.stringify({
              owner_ministry_name: mintry,
              file_type_name: tf,
            }),
            collapsed: Boolean(index === 0),
            children: rawData
              .filter(
                (data: any) =>
                  data.owner_ministry_name === mintry &&
                  data.file_type_name === tf
              )
              .map((d: any) => d.main_category_name)
              .filter(
                (item: any, i: number) =>
                  rawData
                    .filter(
                      (data: any) =>
                        data.owner_ministry_name === mintry &&
                        data.file_type_name === tf
                    )
                    .map((d: any) => d.main_category_name)
                    .indexOf(item) === i
              )
              .map((cg: string) => ({
                name: cg,
                collapsed: Boolean(index === 0),
                data: JSON.stringify({
                  owner_ministry_name: mintry,
                  file_type_name: tf,
                  main_category_name: cg,
                }),
              })),
          })),
      }));

      am4core.createFromConfig(
        {
          series: [
            {
              type: "ForceDirectedSeries",
              data: research,
              dataFields: {
                value: "value",
                name: "name",
                children: "children",
              },
              nodes: {
                label: {
                  text: "{name}",
                  truncate: true,
                },
                tooltipText: `[font-family: 'Prompt-ExtraLight';font-size: 12px; ]{name}`,
                events: {
                  hit: hitEvent,
                },
              },
              fontSize: 12,
              fontWeight: 200,
              fontFamily: "Prompt-ExtraLight",
              maxLevel: 3,
              minRadius: 20,
              maxRadius: 100,
            },
          ],
        },
        rsChart.current ? rsChart.current : "",
        am4plugins_forceDirected.ForceDirectedTree
      );
    }
  }, []);

  useEffect(() => {
    init();
    return () => {};
  }, [init]);

  useEffect(() => {
    if (queryResearch.length) {
      setshow(true);
    } else {
      setshow(false);
    }
    return () => {};
  }, [queryResearch]);

  const downloadEvent = (path: string) => () => {
    if (path.includes("http")) {
      window.open(`${path}`, "_blank");
    } else {
      window.open(`${process.env.REACT_APP_UPLOAD_URL}${path}`, "_blank");
    }
  };

  const _querySubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const rsQuery = rawdata.filter((rs: any) => rs.file_name.includes(keyword));
    setqueryResearch(rsQuery);
  };

  return (
    <Fragment>
      <div className={classes.bg}>
        <ReactResizeDetector
          onResize={(w: number, h: number) => {
            settableheight(h - 200);
          }}
          handleHeight
        />
      </div>
      <Paper ref={paperRef} className={classes.paper} elevation={3}>
        <Grid container spacing={1} style={{ padding: `0px 8px` }}>
          <Grid ref={headerRef} item xs={12}>
            <form onSubmit={_querySubmit}>
              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                style={{ marginBottom: 0 }}
              >
                <OutlinedInput
                  id="search-adornment-research"
                  autoFocus
                  style={{ borderRadius: 32 }}
                  value={keyword}
                  onChange={(e: any) => setkeyword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type="submit" style={{ display: "none" }} />
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        onClick={() => {
                          setkeyword("");
                          setqueryResearch([]);
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={show}>
              <TableContainer
                style={{
                  height: tableheight,
                }}
              >
                <Table stickyHeader aria-label="research-table">
                  <TableBody>
                    {queryResearch
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row._id}>
                            <TableCell>{row.file_name}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                disabled={Boolean(!row.file_url)}
                                onClick={downloadEvent(row.file_url)}
                              >
                                <CloudDownload
                                  color={row.file_url ? "primary" : "disabled"}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={
                  queryResearch && queryResearch.length
                    ? queryResearch.length
                    : 0
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              ฐานข้อมูลงานวิจัยด้านการท่องเที่ยว
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            <div ref={rsChart} />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default TourismResearch;
