import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import * as am4core from "@amcharts/amcharts4/core";
import { UserContextProvider } from "./contexts/user-info";

am4core.addLicense("CH212555597");
am4core.addLicense("MP212555597");

const theme = createMuiTheme({
  typography: {
    fontFamily: "Prompt-Light",
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
