import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import { Person } from "@material-ui/icons";
import TicLogo from "../material/images/tic-logo.png";

const useStyles = makeStyles((theme: Theme) => ({
  colorWhite: {
    color: "#ffffff",
  },
  flex: { display: "flex" },
  flexMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
    // backgroundColor: theme.palette.success.main,
    borderRadius: theme.spacing(5),
    boxShadow: theme.shadows[5],
  },
  hoverClick: { "&:hover": { cursor: "pointer" } },
}));

const Bandner = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down("xs"));

  const goToLandingPage = () => {
    history.push("/app/landing");
  };

  return (
    <div
      className={clsx(
        classes.flex,
        history.location.pathname !== "/app/landing" && classes.hoverClick
      )}
      onClick={goToLandingPage}
    >
      <div className={classes.flexMiddle}>
        <img
          src={TicLogo}
          alt="tic-logo"
          width={40}
          className={classes.avatar}
        />
      </div>
      {!downXs && (
        <div>
          <Typography variant="h6">
            Welcome to Tourism Intelligence Center
          </Typography>
          <Typography variant="body2">
            Ministry of Tourism and Sports, Thailand
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Bandner;
