import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import login from './login'

export default () => {
    const match = useRouteMatch()
    return <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
        <Route path={`${match.url}/login`} component={login} />
        <Redirect to="/not-found" />
    </Switch >
}


