import React, { Fragment, useState, ChangeEvent, useContext } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import LoginVideo from "../../material/video/login.mov";
import Logo from "../../material/images/logo.png";
import {
  Grid,
  useMediaQuery,
  Paper,
  Typography,
  TextField,
  Fab,
} from "@material-ui/core";
import { AccountCircle, Lock } from "@material-ui/icons";
import { signIn } from "../../api/auth";
import { UserContext } from "../../contexts/user-info";
import { _dashboardCategories } from "../../App";

const useStyles = makeStyles((theme: Theme) => ({
  bgRoot: {
    position: "absolute",
    height: `100vh`,
    width: "100vw",
    zIndex: -1,
    backgroundImage: `linear-gradient(45deg, #00f2603b, #0575e63b)`,
  },
  footer: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    // maxWidth: theme.breakpoints.values.sm,
    zIndex: 5,
  },
  footerColorText: {
    color: theme.palette.common.white,
  },
  videoDefault: {
    position: `fixed`,
    zIndex: -2,
    overflow: "unset",
  },
  videoIn1609: {
    width: `100%`,
    height: `auto`,
  },
  videoLess1609: {
    width: `auto`,
    height: `100%`,
  },
  videoHidden: {
    display: `none`,
  },
  flexCenter: { display: "flex", justifyContent: "center" },
  flexMiddle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexKeepRight: { display: "flex", justifyContent: "flex-end" },
  card: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  cardBgLarge: { backgroundColor: `#ffffff9c` },
  cardBgSmall: { backgroundColor: `#ffffff` },
  btn: {
    textTransform: "unset",
    padding: `0px ${theme.spacing(2)}px !important`,
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const [param, setparam] = useState<{ user: string; pass: string }>({
    user: "",
    pass: "",
  });
  const [error, seterror] = useState<Boolean>(false);
  const userContext = useContext(UserContext);
  const { setUserInfo } = userContext;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { user, pass } = param;
    const rsSignIn = await signIn(user, pass);
    if (rsSignIn && rsSignIn.token) {
      seterror(false);
      localStorage.setItem("token", rsSignIn.token);
      localStorage.setItem("id", rsSignIn.user_id);
      const dashboardCategories = await _dashboardCategories();

      const roleId = rsSignIn.user_role;
      const countDashboardRole = dashboardCategories.categories.map(
        (category: any) =>
          category.id !== 5
            ? {
                ...category,
                countDashboard: dashboardCategories.dashboards.filter(
                  (ds2: any) =>
                    ds2.category_id === category.id &&
                    ds2.roles.find((r: any) => r._id === roleId)
                ).length,
              }
            : { ...category, countDashboard: 1 }
      );
      setUserInfo({
        dasboard: dashboardCategories.dashboards.filter((dashboard: any) =>
          dashboard.roles.find((r: any) => r._id === roleId)
        ),
        categories: countDashboardRole.filter(
          (category: any) => category.countDashboard > 0
        ),
        role_id: roleId,
      });
      history.push("/app");
    } else {
      seterror(true);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setparam({ ...param, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      {useMediaQuery("(max-width: 767px)") && (
        <div className={classes.bgRoot} />
      )}
      <div
        className={clsx(
          classes.videoDefault,
          useMediaQuery("(min-aspect-ratio: 16/9)") && classes.videoIn1609,
          useMediaQuery("(max-aspect-ratio: 16/9)") && classes.videoLess1609,
          useMediaQuery("(max-width: 767px)") && classes.videoHidden
        )}
      >
        <video loop autoPlay>
          <source src={LoginVideo} type="video/mp4" />
        </video>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={false} md={6} lg={8} />
          <Grid item xs={12} md={6} lg={4} className={classes.flexCenter}>
            <div
              style={{ height: "100vh", width: "100%" }}
              className={classes.flexMiddle}
            >
              <Paper
                className={clsx(
                  classes.card,
                  useMediaQuery("(max-width: 767px)") && classes.cardBgSmall,
                  !useMediaQuery("(max-width: 767px)") && classes.cardBgLarge
                )}
              >
                <Grid container spacing={2}>
                  {useMediaQuery("(max-width: 767px)") ? (
                    <Fragment>
                      <Grid item xs={12} className={classes.flexCenter}>
                        <img
                          src={Logo}
                          alt="logo"
                          width={80}
                          height={80}
                          style={{ padding: 8 }}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.flexCenter}>
                        <div className={classes.flexMiddle}>
                          <Typography variant="h5">
                            Tourism Intelligence Center
                          </Typography>
                          <Typography variant="subtitle1">
                            ศูนย์ข้อมูลสารสนเทศเพื่อการท่องเที่ยวประเทศไทย
                          </Typography>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid item xs={12} className={classes.flexCenter}>
                      <div
                        className={classes.flexMiddle}
                        style={{ height: "100%" }}
                      >
                        <img
                          src={Logo}
                          alt="logo"
                          width={80}
                          height={80}
                          style={{ padding: 8 }}
                        />
                      </div>
                      <div className={classes.flexMiddle}>
                        <Typography variant="h5">
                          Tourism Intelligence Center
                        </Typography>
                        <Typography variant="subtitle1">
                          ศูนย์ข้อมูลสารสนเทศเพื่อการท่องเที่ยวประเทศไทย
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  <Grid item className={classes.flexMiddle}>
                    <AccountCircle style={{ marginRight: 8, paddingTop: 8 }} />
                  </Grid>
                  <Grid
                    item
                    className={classes.flexMiddle}
                    style={{ width: `calc(100% - 48px)` }}
                  >
                    <TextField
                      name="user"
                      label="Username"
                      value={param.user}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item className={classes.flexMiddle}>
                    <Lock style={{ marginRight: 8, paddingTop: 8 }} />
                  </Grid>
                  <Grid
                    item
                    className={classes.flexMiddle}
                    style={{ width: `calc(100% - 48px)` }}
                  >
                    <TextField
                      name="pass"
                      label="Password"
                      type="password"
                      value={param.pass}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {error && (
                      <Typography
                        variant="caption"
                        component="span"
                        color="error"
                      >
                        User หรือ Password ไม่ถูกต้อง
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.flexKeepRight}>
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      aria-label="login"
                      className={classes.btn}
                      type="submit"
                    >
                      Login
                    </Fab>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textPrimary">
                      สงวนลิขสิทธิ์ กระทรวงท่องเที่ยวและกีฬา 2563
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </form>
      <Grid container className={classes.footer}>
        <Grid item xs={false} md={6} lg={8} />
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          style={{
            padding: `0px 24px`,
          }}
        >
          <Typography
            className={classes.footerColorText}
            variant="caption"
            component="p"
          >
            Tourism Intelligence Center
            เป็นระบบที่นำข้อมูลด้านการท่องเที่ยวของประเทศไทยนำเสนอในรูปแบบของแผนภาพและรูปภาพที่มีความยืดหยุ่น
            (dynamic dashboard) ในหลากหลายรูปแบบ
            ดังนั้นเพื่อให้ข้อมูลถูกส่งต่อไปยังผู้ที่เข้ามาใช้งานได้อย่างถูกต้องและครบถ้วน
            เราแนะนำให้เครื่องคอมพิวเตอร์ของคุณมีรายละเอียดของหน้าจอขนาดขั้นต่ำที่
            1920 x 1080 pixels หรือสุงกว่า สำหรับอุปกรณ์ประเภท Tablet
            ควรมีขนาดจอขั้นต่ำที่ 1024 x 768 pixels หรือสุงกว่า
          </Typography>
          <br />
          <Typography
            className={classes.footerColorText}
            variant="caption"
            component="p"
          >
            กรณีที่พบปัญหาการใช้งานข้อมูลสามารถติดต่อเจ้าหน้าที่ได้ที่ email :
            data@mots.go.th
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Login;
