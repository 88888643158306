export const provinces = [
  {
    p_code: "BKK",
    prov_code: 10,
    prov_name_t: "กรุงเทพมหานคร",
    prov_name_e: "Krung Thep Maha Nakhon",
    minx: 100.3279,
    miny: 13.4884,
    maxx: 100.9386,
    maxy: 13.9553,
  },
  {
    p_code: "SPK",
    prov_code: 11,
    prov_name_t: "สมุทรปราการ",
    prov_name_e: "Changwat Samut Prakarn",
    minx: 100.4445,
    miny: 13.4781,
    maxx: 100.9627,
    maxy: 13.7184,
  },
  {
    p_code: "NB",
    prov_code: 12,
    prov_name_t: "นนทบุรี",
    prov_name_e: "Changwat Nonthaburi",
    minx: 100.2622,
    miny: 13.789,
    maxx: 100.5677,
    maxy: 14.1403,
  },
  {
    p_code: "PN",
    prov_code: 13,
    prov_name_t: "ปทุมธานี",
    prov_name_e: "Changwat Pathum Thani",
    minx: 100.3319,
    miny: 13.9169,
    maxx: 100.9517,
    maxy: 14.2755,
  },
  {
    p_code: "AY",
    prov_code: 14,
    prov_name_t: "พระนครศรีอยุธยา",
    prov_name_e: "Changwat Phra Nakhon Si Ayutthaya",
    minx: 100.2248,
    miny: 14.1104,
    maxx: 100.8237,
    maxy: 14.6731,
  },
  {
    p_code: "AT",
    prov_code: 15,
    prov_name_t: "อ่างทอง",
    prov_name_e: "Changwat Ang Thong",
    minx: 100.1912,
    miny: 14.4389,
    maxx: 100.5158,
    maxy: 14.8019,
  },
  {
    p_code: "LB",
    prov_code: 16,
    prov_name_t: "ลพบุรี",
    prov_name_e: "Changwat Lopburi",
    minx: 100.4195,
    miny: 14.6463,
    maxx: 101.4061,
    maxy: 15.7504,
  },
  {
    p_code: "SH",
    prov_code: 17,
    prov_name_t: "สิงห์บุรี",
    prov_name_e: "Changwat Singburi",
    minx: 100.1814,
    miny: 14.7187,
    maxx: 100.4882,
    maxy: 15.1199,
  },
  {
    p_code: "CHN",
    prov_code: 18,
    prov_name_t: "ชัยนาท",
    prov_name_e: "Changwat Chainat",
    minx: 99.7196,
    miny: 14.9018,
    maxx: 100.3516,
    maxy: 15.4175,
  },
  {
    p_code: "SB",
    prov_code: 19,
    prov_name_t: "สระบุรี",
    prov_name_e: "Changwat Saraburi",
    minx: 100.575,
    miny: 14.2437,
    maxx: 101.4544,
    maxy: 15.0742,
  },
  {
    p_code: "CH",
    prov_code: 20,
    prov_name_t: "ชลบุรี",
    prov_name_e: "Changwat Chonburi",
    minx: 100.7645,
    miny: 12.5079,
    maxx: 101.7186,
    maxy: 13.5896,
  },
  {
    p_code: "RY",
    prov_code: 21,
    prov_name_t: "ระยอง",
    prov_name_e: "Changwat Rayong",
    minx: 100.9845,
    miny: 12.5214,
    maxx: 101.8305,
    maxy: 13.1635,
  },
  {
    p_code: "JB",
    prov_code: 22,
    prov_name_t: "จันทบุรี",
    prov_name_e: "Changwat Chanthaburi",
    minx: 101.6855,
    miny: 12.2859,
    maxx: 102.5361,
    maxy: 13.3357,
  },
  {
    p_code: "TD",
    prov_code: 23,
    prov_name_t: "ตราด",
    prov_name_e: "Changwat Trat",
    minx: 102.2442,
    miny: 11.5617,
    maxx: 102.9149,
    maxy: 12.7574,
  },
  {
    p_code: "CHS",
    prov_code: 24,
    prov_name_t: "ฉะเชิงเทรา",
    prov_name_e: "Changwat Chachoengsao",
    minx: 100.8479,
    miny: 13.1785,
    maxx: 101.9902,
    maxy: 13.9765,
  },
  {
    p_code: "PBR",
    prov_code: 25,
    prov_name_t: "ปราจีนบุรี",
    prov_name_e: "Changwat Prachinburi",
    minx: 101.137,
    miny: 13.5823,
    maxx: 102.1067,
    maxy: 14.4628,
  },
  {
    p_code: "NY",
    prov_code: 26,
    prov_name_t: "นครนายก",
    prov_name_e: "Changwat Nakhon Nayok",
    minx: 100.9134,
    miny: 13.961,
    maxx: 101.5054,
    maxy: 14.5128,
  },
  {
    p_code: "SAK",
    prov_code: 27,
    prov_name_t: "สระแก้ว",
    prov_name_e: "Changwat Srakaeo",
    minx: 101.8737,
    miny: 13.2366,
    maxx: 102.941,
    maxy: 14.1963,
  },
  {
    p_code: "NR",
    prov_code: 30,
    prov_name_t: "นครราชสีมา",
    prov_name_e: "Changwat Nakhon Ratchasima",
    minx: 101.181,
    miny: 14.12,
    maxx: 103.0127,
    maxy: 15.808,
  },
  {
    p_code: "BR",
    prov_code: 31,
    prov_name_t: "บุรีรัมย์",
    prov_name_e: "Changwat Burirum",
    minx: 102.4313,
    miny: 14.1306,
    maxx: 103.5049,
    maxy: 15.7962,
  },
  {
    p_code: "SR",
    prov_code: 32,
    prov_name_t: "สุรินทร์",
    prov_name_e: "Changwat Surin",
    minx: 103.0892,
    miny: 14.327,
    maxx: 104.0923,
    maxy: 15.483,
  },
  {
    p_code: "SK",
    prov_code: 33,
    prov_name_t: "ศรีสะเกษ",
    prov_name_e: "Changwat Sisaket",
    minx: 103.9021,
    miny: 14.342,
    maxx: 104.9086,
    maxy: 15.5689,
  },
  {
    p_code: "UB",
    prov_code: 34,
    prov_name_t: "อุบลราชธานี",
    prov_name_e: "Changwat Ubon Ratchathani",
    minx: 104.3722,
    miny: 14.209,
    maxx: 105.637,
    maxy: 16.098,
  },
  {
    p_code: "YS",
    prov_code: 35,
    prov_name_t: "ยโสธร",
    prov_name_e: "Changwat Yasothon",
    minx: 103.9971,
    miny: 15.289,
    maxx: 104.8237,
    maxy: 16.3472,
  },
  {
    p_code: "SHP",
    prov_code: 36,
    prov_name_t: "ชัยภูมิ",
    prov_name_e: "Changwat Chaiyaphum",
    minx: 101.3171,
    miny: 15.3314,
    maxx: 102.4594,
    maxy: 16.7307,
  },
  {
    p_code: "ANR",
    prov_code: 37,
    prov_name_t: "อำนาจเจริญ",
    prov_name_e: "Changwat Amnaj Charoen",
    minx: 104.4186,
    miny: 15.5374,
    maxx: 105.0603,
    maxy: 16.2834,
  },
  {
    p_code: "BK",
    prov_code: 38,
    prov_name_t: "บึงกาฬ",
    prov_name_e: "Changwat Bueng Kan",
    minx: 103.2433,
    miny: 17.771,
    maxx: 104.1903,
    maxy: 18.4488,
  },
  {
    p_code: "NBP",
    prov_code: 39,
    prov_name_t: "หนองบัวลำภู",
    prov_name_e: "Changwat Nong Bua Lamphu",
    minx: 101.9844,
    miny: 16.7713,
    maxx: 102.6813,
    maxy: 17.6788,
  },
  {
    p_code: "KK",
    prov_code: 40,
    prov_name_t: "ขอนแก่น",
    prov_name_e: "Changwat Khon Kaen",
    minx: 101.7499,
    miny: 15.632,
    maxx: 103.1843,
    maxy: 17.0884,
  },
  {
    p_code: "UD",
    prov_code: 41,
    prov_name_t: "อุดรธานี",
    prov_name_e: "Changwat Udon Thani",
    minx: 102.0171,
    miny: 16.8016,
    maxx: 103.6672,
    maxy: 18.0862,
  },
  {
    p_code: "LY",
    prov_code: 42,
    prov_name_t: "เลย",
    prov_name_e: "Changwat Loei",
    minx: 100.8334,
    miny: 16.7533,
    maxx: 102.1535,
    maxy: 18.2197,
  },
  {
    p_code: "NK",
    prov_code: 43,
    prov_name_t: "หนองคาย",
    prov_name_e: "Changwat Nong Khai",
    minx: 102.0552,
    miny: 17.5922,
    maxx: 103.4135,
    maxy: 18.3041,
  },
  {
    p_code: "MSK",
    prov_code: 44,
    prov_name_t: "มหาสารคาม",
    prov_name_e: "Changwat Mahasarakham",
    minx: 102.8435,
    miny: 15.4054,
    maxx: 103.4941,
    maxy: 16.6438,
  },
  {
    p_code: "RE",
    prov_code: 45,
    prov_name_t: "ร้อยเอ็ด",
    prov_name_e: "Changwat Roi Et",
    minx: 103.2715,
    miny: 15.405,
    maxx: 104.3522,
    maxy: 16.4731,
  },
  {
    p_code: "KS",
    prov_code: 46,
    prov_name_t: "กาฬสินธุ์",
    prov_name_e: "Changwat Kalasin",
    minx: 103.0974,
    miny: 16.1819,
    maxx: 104.2403,
    maxy: 17.102,
  },
  {
    p_code: "SN",
    prov_code: 47,
    prov_name_t: "สกลนคร",
    prov_name_e: "Changwat Sakon Nakhon",
    minx: 103.2512,
    miny: 16.7723,
    maxx: 104.4353,
    maxy: 18.0892,
  },
  {
    p_code: "NPN",
    prov_code: 48,
    prov_name_t: "นครพนม",
    prov_name_e: "Changwat Nakhon Phanom",
    minx: 103.9817,
    miny: 16.787,
    maxx: 104.8066,
    maxy: 18.024,
  },
  {
    p_code: "MH",
    prov_code: 49,
    prov_name_t: "มุกดาหาร",
    prov_name_e: "Changwat Mukdahan",
    minx: 104.0708,
    miny: 16.1863,
    maxx: 104.9799,
    maxy: 16.8936,
  },
  {
    p_code: "CHM",
    prov_code: 50,
    prov_name_t: "เชียงใหม่",
    prov_name_e: "Changwat Chiang Mai",
    minx: 98.045,
    miny: 17.2423,
    maxx: 99.573,
    maxy: 20.1475,
  },
  {
    p_code: "LPN",
    prov_code: 51,
    prov_name_t: "ลำพูน",
    prov_name_e: "Changwat Lamphun",
    minx: 98.6707,
    miny: 17.4239,
    maxx: 99.3203,
    maxy: 18.71,
  },
  {
    p_code: "LP",
    prov_code: 52,
    prov_name_t: "ลำปาง",
    prov_name_e: "Changwat Lampang",
    minx: 98.88,
    miny: 17.2064,
    maxx: 100.1258,
    maxy: 19.4178,
  },
  {
    p_code: "UTD",
    prov_code: 53,
    prov_name_t: "อุตรดิตถ์",
    prov_name_e: "Changwat Auttaradit",
    minx: 99.8947,
    miny: 17.1374,
    maxx: 101.1926,
    maxy: 18.3816,
  },
  {
    p_code: "PR",
    prov_code: 54,
    prov_name_t: "แพร่",
    prov_name_e: "Changwat Phrae",
    minx: 99.3673,
    miny: 17.6856,
    maxx: 100.5511,
    maxy: 18.8344,
  },
  {
    p_code: "NN",
    prov_code: 55,
    prov_name_t: "น่าน",
    prov_name_e: "Changwat Nan",
    minx: 100.3363,
    miny: 18.0134,
    maxx: 101.357,
    maxy: 19.6342,
  },
  {
    p_code: "PY",
    prov_code: 56,
    prov_name_t: "พะเยา",
    prov_name_e: "Changwat Phayao",
    minx: 99.6827,
    miny: 18.8054,
    maxx: 100.6291,
    maxy: 19.736,
  },
  {
    p_code: "CHR",
    prov_code: 57,
    prov_name_t: "เชียงราย",
    prov_name_e: "Changwat Chiangrai",
    minx: 99.2583,
    miny: 19,
    maxx: 100.5779,
    maxy: 20.4649,
  },
  {
    p_code: "MS",
    prov_code: 58,
    prov_name_t: "แม่ฮ่องสอน",
    prov_name_e: "Changwat Mae Hong Son",
    minx: 97.3437,
    miny: 17.6359,
    maxx: 98.6517,
    maxy: 19.8152,
  },
  {
    p_code: "NS",
    prov_code: 60,
    prov_name_t: "นครสวรรค์",
    prov_name_e: "Changwat Nakhon Sawan",
    minx: 99.0861,
    miny: 15.0527,
    maxx: 100.8335,
    maxy: 16.1918,
  },
  {
    p_code: "UT",
    prov_code: 61,
    prov_name_t: "อุทัยธานี",
    prov_name_e: "Changwat Uthai Thani",
    minx: 98.9844,
    miny: 14.9378,
    maxx: 100.1068,
    maxy: 15.7982,
  },
  {
    p_code: "KP",
    prov_code: 62,
    prov_name_t: "กำแพงเพชร",
    prov_name_e: "Changwat Kampaeng Phet",
    minx: 99.0161,
    miny: 15.8582,
    maxx: 100.0461,
    maxy: 16.9135,
  },
  {
    p_code: "TK",
    prov_code: 63,
    prov_name_t: "ตาก",
    prov_name_e: "Changwat Tak",
    minx: 97.7426,
    miny: 15.1803,
    maxx: 99.4687,
    maxy: 17.8682,
  },
  {
    p_code: "SKT",
    prov_code: 64,
    prov_name_t: "สุโขทัย",
    prov_name_e: "Changwat Sukhothai",
    minx: 99.3119,
    miny: 16.683,
    maxx: 100.1116,
    maxy: 17.8217,
  },
  {
    p_code: "PNL",
    prov_code: 65,
    prov_name_t: "พิษณุโลก",
    prov_name_e: "Changwat Phitsanu Lok",
    minx: 99.8534,
    miny: 16.3204,
    maxx: 101.11,
    maxy: 17.743,
  },
  {
    p_code: "PJ",
    prov_code: 66,
    prov_name_t: "พิจิตร",
    prov_name_e: "Changwat Phichit",
    minx: 99.9834,
    miny: 15.9238,
    maxx: 100.7991,
    maxy: 16.6477,
  },
  {
    p_code: "PCH",
    prov_code: 67,
    prov_name_t: "เพชรบูรณ์",
    prov_name_e: "Changwat Phetchabun",
    minx: 100.6353,
    miny: 15.3196,
    maxx: 101.7918,
    maxy: 17.1782,
  },
  {
    p_code: "RB",
    prov_code: 70,
    prov_name_t: "ราชบุรี",
    prov_name_e: "Changwat Ratchaburi",
    minx: 99.1646,
    miny: 13.149,
    maxx: 100.0679,
    maxy: 13.962,
  },
  {
    p_code: "KBR",
    prov_code: 71,
    prov_name_t: "กาญจนบุรี",
    prov_name_e: "Changwat Kanchanaburi",
    minx: 98.1823,
    miny: 13.7255,
    maxx: 99.8797,
    maxy: 15.6607,
  },
  {
    p_code: "SP",
    prov_code: 72,
    prov_name_t: "สุพรรณบุรี",
    prov_name_e: "Changwat Suphan Buri",
    minx: 99.2811,
    miny: 14.0609,
    maxx: 100.2811,
    maxy: 15.0818,
  },
  {
    p_code: "NP",
    prov_code: 73,
    prov_name_t: "นครปฐม",
    prov_name_e: "Changwat Nakhon Prathom",
    minx: 99.8188,
    miny: 13.6483,
    maxx: 100.3369,
    maxy: 14.1789,
  },
  {
    p_code: "SMR",
    prov_code: 74,
    prov_name_t: "สมุทรสาคร",
    prov_name_e: "Changwat Samut Sakhon",
    minx: 100.0277,
    miny: 13.4242,
    maxx: 100.4196,
    maxy: 13.7224,
  },
  {
    p_code: "SKM",
    prov_code: 75,
    prov_name_t: "สมุทรสงคราม",
    prov_name_e: "Changwat Samut Songkham",
    minx: 99.852,
    miny: 13.2416,
    maxx: 100.0796,
    maxy: 13.5155,
  },
  {
    p_code: "PB",
    prov_code: 76,
    prov_name_t: "เพชรบุรี",
    prov_name_e: "Changwat Phetchaburi",
    minx: 99.0994,
    miny: 12.5615,
    maxx: 100.1023,
    maxy: 13.3436,
  },
  {
    p_code: "PKK",
    prov_code: 77,
    prov_name_t: "ประจวบคีรีขันธ์",
    prov_name_e: "Changwat Prachuap Khilikhan",
    minx: 99.147,
    miny: 10.9556,
    maxx: 100.0199,
    maxy: 12.6452,
  },
  {
    p_code: "NSR",
    prov_code: 80,
    prov_name_t: "นครศรีธรรมราช",
    prov_name_e: "Changwat Nakhon Si Thammarat",
    minx: 99.2342,
    miny: 7.837,
    maxx: 100.3369,
    maxy: 9.3264,
  },
  {
    p_code: "KB",
    prov_code: 81,
    prov_name_t: "กระบี่",
    prov_name_e: "Changwat Krabi",
    minx: 98.6035,
    miny: 7.1968,
    maxx: 99.4157,
    maxy: 8.6739,
  },
  {
    p_code: "PHG",
    prov_code: 82,
    prov_name_t: "พังงา",
    prov_name_e: "Changwat Phang Nga",
    minx: 97.6195,
    miny: 7.7449,
    maxx: 98.7076,
    maxy: 9.4953,
  },
  {
    p_code: "PK",
    prov_code: 83,
    prov_name_t: "ภูเก็ต",
    prov_name_e: "Changwat Phuket",
    minx: 98.2582,
    miny: 7.4785,
    maxx: 98.4717,
    maxy: 8.2004,
  },
  {
    p_code: "STH",
    prov_code: 84,
    prov_name_t: "สุราษฎร์ธานี",
    prov_name_e: "Changwat Surat Thani",
    minx: 98.4424,
    miny: 8.3006,
    maxx: 100.0907,
    maxy: 9.8046,
  },
  {
    p_code: "RN",
    prov_code: 85,
    prov_name_t: "ระนอง",
    prov_name_e: "Changwat Ranong",
    minx: 98.3255,
    miny: 9.3046,
    maxx: 98.9648,
    maxy: 10.7889,
  },
  {
    p_code: "CHP",
    prov_code: 86,
    prov_name_t: "ชุมพร",
    prov_name_e: "Changwat Chumphon",
    minx: 98.6267,
    miny: 9.6007,
    maxx: 99.5383,
    maxy: 11.0335,
  },
  {
    p_code: "SKL",
    prov_code: 90,
    prov_name_t: "สงขลา",
    prov_name_e: "Changwat Songkhla",
    minx: 100.0547,
    miny: 6.2893,
    maxx: 101.1081,
    maxy: 7.9387,
  },
  {
    p_code: "ST",
    prov_code: 91,
    prov_name_t: "สตูล",
    prov_name_e: "Changwat Satun",
    minx: 99.1528,
    miny: 6.4227,
    maxx: 100.2204,
    maxy: 7.198,
  },
  {
    p_code: "TR",
    prov_code: 92,
    prov_name_t: "ตรัง",
    prov_name_e: "Changwat Trang",
    minx: 99.2325,
    miny: 6.9725,
    maxx: 99.9593,
    maxy: 8.0129,
  },
  {
    p_code: "PL",
    prov_code: 93,
    prov_name_t: "พัทลุง",
    prov_name_e: "Changwat Phatthalung",
    minx: 99.7324,
    miny: 7.0903,
    maxx: 100.4277,
    maxy: 7.8976,
  },
  {
    p_code: "PTI",
    prov_code: 94,
    prov_name_t: "ปัตตานี",
    prov_name_e: "Changwat Pattani",
    minx: 101.0188,
    miny: 6.5498,
    maxx: 101.7247,
    maxy: 6.9532,
  },
  {
    p_code: "YL",
    prov_code: 95,
    prov_name_t: "ยะลา",
    prov_name_e: "Changwat Yala",
    minx: 100.8345,
    miny: 5.6127,
    maxx: 101.6088,
    maxy: 6.6838,
  },
  {
    p_code: "NRV",
    prov_code: 96,
    prov_name_t: "นราธิวาส",
    prov_name_e: "Changwat Narathiwat",
    minx: 101.3712,
    miny: 5.7328,
    maxx: 102.0927,
    maxy: 6.6356,
  },
];
