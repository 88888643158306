import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import NotFoundImage from "../material/images/404.png";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
  },
  middle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2),
  },
}));

const Notfound = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.middle}>
        <img src={NotFoundImage} alt="404-pic" />
        <Typography variant="subtitle1" className={classes.center}>
          ไม่พบหน้าที่ต้องการ
        </Typography>
        <div className={classes.center}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/app/landing");
            }}
          >
            กลับไปหน้าหลัก
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
