import React, { useContext, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import Main from "./views";
import Auth from "./views/auth";
import App from "./views/app";
import Notfound from "./views/notfound";
import { UserContext } from "./contexts/user-info";
import { getDashboards, getUser } from "./api";
import TourismSituation from "./views/app/tourism-situation";
import SpatialPlanning from "./views/app/spatial-planning";
import RiskManage from "./views/app/risk-manage";
import AbilityToCompete from "./views/app/ability-to-compete";
import TourismResearch from "./views/app/tourism-research";
import BudgetTracking from "./views/app/budget-tracking";
import TourismInvestment from "./views/app/tourism-investment";
import Unwto from "./views/app/unwto";
import Imf from "./views/app/imf";
import {
  Airplane,
  World,
  Briefcase,
  Ticket,
  Location,
  Guide,
  Hotel,
  IMF,
  Tsa,
  CheckIn,
} from "./material/icons";
import TourismSituationDaily from "./views/app/tourism-situation-daily";

interface CustomRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: CustomRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

const SelfRoute = (props: CustomRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/tourismsituation",
            }}
          />
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
};

export const _dashboardCategories = async () => {
  const rsDashboards = await getDashboards();
  let categories = new Array(0);
  let sortCg = new Array(0);
  console.log(rsDashboards);
  rsDashboards.data.map((ds: any) => {
    const checkHas = sortCg.find((cg: any) => cg.id === ds.category_id);
    if (!checkHas) {
      sortCg.push({ id: ds.category_id, name: ds.category_name });
    }
    return null;
  });
  console.log(sortCg);
  console.log(rsDashboards.data);
  rsDashboards.data.map((ds: any) => {
    const checkHas = categories.find((cg: any) => cg.id === ds.category_id);
    if (!checkHas) {
      categories.push({
        id: ds.category_id,
        name: ds.category_name,
        pathLink:
          ds.category_id === 1
            ? `tourismsituation`
            : ds.category_id === 2
            ? `tourismsituation-dialy`
            : ds.category_id === 3
            ? `spatialplanning`
            : ds.category_id === 4
            ? `riskmanage`
            : ds.category_id === 5
            ? `abilitytocompete`
            : ds.category_id === 6
            ? `tourismresearch`
            : ds.category_id === 7
            ? `budgettracking`
            : ds.category_id === 8
            ? `tourisminvestment`
            : ds.category_id === 9
            ? `tsa`
            : ds.category_id === 10
            ? `imf`
            : `not-found`,
        component:
          ds.category_id === 1
            ? TourismSituation
            : ds.category_id === 2
            ? TourismSituationDaily
            : ds.category_id === 3
            ? SpatialPlanning
            : ds.category_id === 4
            ? RiskManage
            : ds.category_id === 5
            ? AbilityToCompete
            : ds.category_id === 6
            ? TourismResearch
            : ds.category_id === 7
            ? BudgetTracking
            : ds.category_id === 8
            ? TourismInvestment
            : ds.category_id === 9
            ? Unwto
            : ds.category_id === 10
            ? Imf
            : Notfound,
        icon:
          ds.category_id === 1 ? (
            <Airplane
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 2 ? (
            <CheckIn
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 3 ? (
            <Location
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 4 ? (
            <Guide
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 5 ? (
            <World
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 6 ? (
            <Ticket
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 7 ? (
            <Hotel
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 8 ? (
            <Briefcase
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 9 ? (
            <Tsa
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : ds.category_id === 10 ? (
            <IMF
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ) : (
            <Airplane
              style={{
                fontSize: 40,
                color: "#dbdee0",
                padding: 8,
              }}
            />
          ),
      });
    }
    return null;
  });
  // const categoriesHasResearch = categories.map((cg: any) => {
  //   if (cg.id === 6) {
  //     return {
  //       ...cg,
  //       icon: (
  //         <Ticket
  //           style={{
  //             fontSize: 40,
  //             color: "#dbdee0",
  //             padding: 8,
  //           }}
  //         />
  //       ),
  //       component: TourismResearch,
  //       pathLink: "tourismresearch",
  //     };
  //   } else {
  //     return cg;
  //   }
  // });
  // console.log(categoriesHasResearch);
  const allCategories = categories.sort((a: any, b: any) => {
    let comparison = 0;
    if (a.id > b.id) {
      comparison = 1;
    } else if (a.id < b.id) {
      comparison = -1;
    }
    return comparison;
  });
  return { dashboards: rsDashboards.data, categories: allCategories };
};

export default () => {
  const userContext = useContext(UserContext);
  const { userInfo, setUserInfo } = userContext;

  const initPermission = useCallback(async () => {
    const dashboardCategories = await _dashboardCategories();
    if (localStorage.getItem("token") && localStorage.getItem("id")) {
      const rsUser = await getUser(String(localStorage.getItem("id")));
      if (rsUser.data) {
        const roleId = rsUser.data[0].role_id;
        const countDashboardRole = dashboardCategories.categories.map(
          (category: any) =>
            category.id !== 6
              ? {
                  ...category,
                  countDashboard: dashboardCategories.dashboards.filter(
                    (ds2: any) =>
                      ds2.category_id === category.id &&
                      ds2.roles.find((r: any) => r._id === roleId)
                  ).length,
                }
              : { ...category, countDashboard: 1 }
        );
        setUserInfo({
          dasboard: dashboardCategories.dashboards.filter((dashboard: any) =>
            dashboard.roles.find((r: any) => r._id === roleId)
          ),
          categories: countDashboardRole.filter(
            (category: any) => category.countDashboard > 0
          ),
          role_id: roleId,
        });
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        const countDashboardPublic = dashboardCategories.categories.map(
          (category: any) =>
            category.id !== 5
              ? {
                  ...category,
                  countDashboard: dashboardCategories.dashboards.filter(
                    (ds2: any) => ds2.category_id === category.id && ds2.public
                  ).length,
                }
              : { ...category, countDashboard: 1 }
        );
        setUserInfo({
          dasboard: dashboardCategories.dashboards.filter(
            (dashboard: any) => dashboard.public
          ),
          categories: countDashboardPublic.filter(
            (category: any) => category.countDashboard > 0
          ),
        });
      }
    } else {
      const countDashboardPublic = dashboardCategories.categories.map(
        (category: any) =>
          category.id !== 5
            ? {
                ...category,
                countDashboard: dashboardCategories.dashboards.filter(
                  (ds2: any) => ds2.category_id === category.id && ds2.public
                ).length,
              }
            : { ...category, countDashboard: 1 }
      );
      setUserInfo({
        dasboard: dashboardCategories.dashboards.filter(
          (dashboard: any) => dashboard.public
        ),
        categories: countDashboardPublic.filter(
          (category: any) => category.countDashboard > 0
        ),
      });
    }
  }, [setUserInfo]);

  useEffect(() => {
    if (!userInfo) {
      initPermission();
    }

    return () => {};
  }, [userInfo, initPermission]);
  return userInfo ? (
    <Router>
      <Switch>
        <PrivateRoute path="/app" component={App} />
        <SelfRoute path="/auth" component={Auth} />
        <Route path="/" exact component={Main} />
        <Route path="/not-found" component={Notfound} />
        <Redirect to="/not-found" />
      </Switch>
    </Router>
  ) : null;
};
