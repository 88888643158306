export const callToken = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/tableau-trusted`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getResearches = () => {
  try {
    return fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/researches`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getDashboards = () => {
  try {
    return fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/dashboards`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUser = (userId: string) => {
  try {
    return fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}/users/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
